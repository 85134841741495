import { Component, OnInit, AfterViewInit, DoCheck, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/services/auth.service';
import { BsModalService } from "ngx-bootstrap/modal";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  constructor(
    private toAsterService: ToastrService,
    private authService: AuthService,
    public router: Router,
    private modalService: BsModalService,
    private route: ActivatedRoute,
  ) { }

  modalRef: any;
  currentUser: any;

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
  }

  ngDoCheck() {

    if (window.location.pathname.indexOf('coupon') != -1) {
      document.getElementById('couponmenu').scrollIntoView();
    } else if (window.location.pathname.indexOf('coordinates') != -1) {
      document.getElementById('Coordinates').scrollIntoView();
    } else if (window.location.pathname.indexOf('notifications') != -1) {
      document.getElementById('notifications').scrollIntoView();
    } else if (window.location.pathname.indexOf('event-sponsors') != -1) {
      document.getElementById('Sponsors').scrollIntoView();
    } else if (window.location.pathname.indexOf('treasure') != -1) {
      document.getElementById('Treasure').scrollIntoView();
    } else if (window.location.pathname.indexOf('more-sections') != -1) {
      document.getElementById('More-Section').scrollIntoView();
    } else if (window.location.pathname.indexOf('home-partners') != -1) {
      document.getElementById('home-partners').scrollIntoView();
    }


  }


  logout() {
    this.modalRef.hide();

    this.authService.logout({ id: this.currentUser._id }).subscribe((res: any) => {
      console.log(res.status);
      if (res.status == 200) {
        localStorage.removeItem("currentUser");
        localStorage.removeItem("isprofileUpdated");
        localStorage.removeItem("isActive");
        localStorage.removeItem("isClientProfileActive");
      } else {
        this.toAsterService.error(res.error.message);
      }
    });
    this.router.navigateByUrl("/auth/login");
  }

  // modal lougout confirmation
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  confirm() {
    this.logout();
  }
  cancel() {
    this.modalRef.hide();
  }


}
