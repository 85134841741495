import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class SharedService {
  url: any = environment.API_URL;
  constructor(private http: HttpClient) {}

  uploadImage(file: any) {
    return this.http.post(this.url + "upload/image", file);
  }

  uploadVideo(file: any) {
    return this.http.post(this.url + "upload/video", file);
  }

  uploadPdf(file: any) {
    return this.http.post(this.url + "upload/pdf", file);
  }
}
