import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-email-send',
  templateUrl: './email-send.component.html',
  styleUrls: ['./email-send.component.scss']
})
export class EmailSendComponent implements OnInit, OnDestroy {
  submitted: boolean;
  userData: any;
  parsedData: any;
  phoneNumber: any;
  click: boolean;
  isResendDisabled: boolean = false;
  resendTimer: number = 15;
  intervalId: any;
  clickCount: number = 0;
  timerText: string = '00:15'; // Initial value, adjust as needed



  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,

  ) {
  }

  ngOnInit(): void {
    this.userData = localStorage.getItem('securityCheck');
    this.parsedData = JSON.parse(this.userData)
    let no = this.parsedData.userData.phone
    this.phoneNumber = no.slice(-4)
    this.clickCount = parseInt(localStorage.getItem('resendClickCount') || '0', 10);
    if (this.clickCount >= 3) {
      this.startResendTimer(900); // 900 seconds = 15 minutes
    } else {
      this.startResendTimer();
    }
  }

  otpForm = new FormGroup({
    otp: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{1,9}$')])
  })

  get f() {
    return this.otpForm.controls;
  }

  ngOnDestroy() {
    this.clearResendTimer();
  }

  login() {
    this.isResendDisabled = true;
    if (this.clickCount >= 3) {
      setTimeout(() => {
        this.isResendDisabled = false;
      }, 900000);
    } else {
      setTimeout(() => {
        this.isResendDisabled = false;
      }, 15000);
    }

    let data = this.authService.getCredentials();
    let user: any = {
      email: data.email,
      password: data.password,
    };
    this.startResendTimer();
    this.authService.login(user).subscribe(
      (res: any) => {
        if (res.status == 200) {
          localStorage.setItem('securityCheck', JSON.stringify(res.output));
          this.toastr.success("OTP sent successfully")
          this.clickCount++;
          localStorage.setItem('resendClickCount', this.clickCount.toString());
          if (this.clickCount >= 3) {
            this.clearResendTimer();
            this.startResendTimer(900); // 900 seconds = 15 minutes
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  numberOnly(evt: any): boolean {
    let value = evt.target.value;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 8 || charCode == 37) {
      return true;
    } else if (charCode == 46 && value.indexOf(".") != -1) {
      return false;
    } else if (
      charCode > 31 &&
      charCode != 46 &&
      (charCode < 48 || charCode > 57)
    ) {
      return false;
    }
    return true;
  }

  resetPassword() {
    this.submitted = true;
    if (this.otpForm.invalid || this.otpForm.pristine) return;
    this.submitted = false;

    let user: any = {
      code: this.otpForm.value.otp,
      id: this.parsedData.userData._id,
    };

    this.authService.verifyMfaCode(user).subscribe(
      (res: any) => {
        if (res.status == 200) {
          localStorage.removeItem('resendClickCount'); // Clear the click count from localStorage
          localStorage.setItem('currentUser', JSON.stringify(res.output));
          this.authService.clearCredentials();
          this.router.navigateByUrl('/analytics-dashboard');
          localStorage.removeItem('securityCheck');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  startResendTimer(duration: number = 15) {
    this.isResendDisabled = true;
    this.resendTimer = duration;
    this.intervalId = setInterval(() => {
      const minutes = Math.floor(this.resendTimer / 60);
      const seconds = this.resendTimer % 60;
      this.resendTimer--;

      // Pad single-digit minutes and seconds with leading zeros
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

      this.timerText = `${formattedMinutes}:${formattedSeconds}`;

      if (this.resendTimer < 0) {
        this.clearResendTimer();
      }
    }, 1000);
  }

  clearResendTimer() {
    this.isResendDisabled = false;
    this.resendTimer = 15;
    clearInterval(this.intervalId);
    this.updateTimerText(); // Reset timer text to initial value (e.g., '00:15')

  }

  updateTimerText() {
    const minutes = Math.floor(this.resendTimer / 60);
    const seconds = this.resendTimer % 60;

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    this.timerText = `${formattedMinutes}:${formattedSeconds}`;
  }


}
