import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { User } from '../models/user.model';
import { Subject } from "rxjs";
import * as XLSX from 'xlsx';


@Injectable()

export class AuthService {

    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;
    private readonly credentialsKey = 'loginCredentials';



    private emailSend = new Subject<any>();
    child1$ = this.emailSend.asObservable();


    SendToChild1(msg: string) {
        this.emailSend.next(msg);
    }

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient,
    ) {
      
     }

    public get currentUserValue() {
        let data = localStorage.getItem('currentUser');
        let obj: Object = {};
        if (data !== 'undefined')
            return JSON.parse(localStorage.getItem('currentUser'));
        return false;
    }

    public get basicAuthentication() {
        let data = {
            userName: '3xMF@3#',
            password: '9raPMFHOMECARE@3!)#@done'
        }
        return data;
    }

    public get language() {
        let lang = localStorage.getItem('lang');
        console.log('lang', lang);
        if (lang) {
            return lang;
        }
        return false;
    }

    saveCredentials(credentials) {
        sessionStorage.setItem(this.credentialsKey, JSON.stringify(credentials));
    }

    getCredentials() {
        const credentialsString = sessionStorage.getItem(this.credentialsKey);
        if (credentialsString) {
            return JSON.parse(credentialsString);
        }
    }

    clearCredentials() {
        sessionStorage.removeItem(this.credentialsKey);
    }

    login(user: User) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + btoa(this.basicAuthentication.userName + ':' + this.basicAuthentication.password)
            })
        };
        return this.http.post<any>(this.url + 'user/admin/login', user, httpOptions);
    }

    verifyMfaCode(user: User) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + btoa(this.basicAuthentication.userName + ':' + this.basicAuthentication.password)
            })
        };
        return this.http.post<any>(this.url + 'user/verify-mfa-code', user, httpOptions);
    }

    reset(data: any) {
        return this.http.post(this.url + 'user/reset-password', data);
    }


    forgetPassword(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + btoa(this.basicAuthentication.userName + ':' + this.basicAuthentication.password)
            })
        };
        return this.http.post(this.url + 'user/forgot-password', data, httpOptions);
    }




    logout(data: any) {
        // localStorage.removeItem('currentUser');
        return this.http.post(this.url + 'user/logout', data);
    }

    updateuser(data: any) {
        return this.http.patch(this.url + 'user/update', data);
    }

    changePassword(data: any) {
        return this.http.post(this.url + 'user/change-password', data);
    }

    emailValidation(data: any) {
        return this.http.post(this.url + "user/emailExists", data);
    }

    exportToExcel(data): void {
        const randomId: string = Math.random().toString(36).substring(7); 
        const filename: string = `List-of-users-${randomId}.xlsx`;
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, filename); 
    }


}