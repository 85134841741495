import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { error } from 'src/app/_helpers/error';
import { User } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  submitted: boolean = false;
  error = error.err;
  loginForm: FormGroup;

  // ***********************
  hide = true;
  hide1 = true;
  hide2 = true;
  // *********************

  userType: any = 1;

  check: number = 1;


  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) { }



  receiveEmail: any;

  ngOnInit() {
    this.initForm();
    let a = localStorage.getItem('receiveEmail');
    let b = a?.slice(1, a.length - 1);
    this.loginForm.get("email").patchValue(b);
    // localStorage.removeItem('receiveEmail'); 
  }

  get f() {
    return this.loginForm.controls;
  }

  initForm() {
    this.loginForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
  }

  onAdminSelection(event: any) {
    console.log('admin', event);
    if (event.target.checked == true) {
      this.userType = 1;
      this.check = 1;
    }
  }

  onSupportSelection(event: any) {
    if (event.target.checked == true) {
      this.userType = 5;
      this.check = 2;
    }
  }

  onZoneSelection(event: any) {
    if (event.target.checked == true) {
      this.userType = 6;
      this.check = 3;
    }
  }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid || this.loginForm.pristine) return;
    this.submitted = false;

    let user: any = {
      email: this.loginForm.value.email.toLowerCase(),
      password: this.loginForm.value.password,
      // userType: 1,
    };
    this.authService.saveCredentials(user);
    this.authService.login(user).subscribe(
      (res: any) => {
        if (res.status == 200) {
          localStorage.setItem('securityCheck', JSON.stringify(res.output));
          this.router.navigateByUrl('/otp-verification');
          localStorage.removeItem('receiveEmail');
        }
        if (res.status == 400) {
          this.toastr.error("Invalid email or password");
        }
      },
      (err) => {
        console.log(err);
        // this.toastr.error('Invalid email or password');
      }
    );
  }
}
