import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth/services/auth.service';
import { OnInit } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle';
import { Router, RouterModule, Routes } from '@angular/router';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Riyadh-Seasons-admin';
  status = 'ONLINE';
  isConnected = true;

  currentUser:any;

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
  }
  constructor(
    private connectionService: ConnectionService,
    private toastrService: ToastrService,
    private authService: AuthService,
    private bnIdle: BnNgIdleService,
    private router: Router,
    
    

  ) {
    this.bnIdle.startWatching(7200).subscribe((res) => {
      if (res) {
        console.log("session expired");
        this.currentUser = this.authService.currentUserValue;
        if (this.currentUser) {
          this.authService.logout({ id: this.currentUser._id }).subscribe((res: any) => {
            console.log(res.status);
            if (res) {
              localStorage.removeItem("currentUser");
              this.router.navigateByUrl("/auth/login");
            }
          });
        }
        this.router.navigateByUrl("/auth/login");
        this.toastrService.error('session expired');
      }
    })

    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = "ONLINE";
      }
      else {
        this.status = "OFFLINE";
        this.authService.logout(this.currentUser._id);
        this.toastrService.error('Connection Lost');
      }
    })
  }

  
}
