<app-layout></app-layout>
<div class="content">
    <div class="row mt-4">
            <div class="total-counts">
                <div class="total-count total-users col-lg-5 mt-3 card-border" routerLink="/manage/clients">
                    <img src="../../../assets/little/SVG_LOGO/SVG_LOGO/Devices.svg" alt="" height="60" width="60">
                    <div>
                        <h2 id="client" class="counter">{{clientCounts}}</h2>
                        <p>Total Number of Registered Clients</p>
                    </div>
                </div>

                <div class="total-count total-careers  col-lg-5 mt-3 card-border" routerLink="/caregiver">
                    <img src="../../../assets/little/SVG_LOGO/SVG_LOGO/Downloads.svg" alt="" height="60" width="60">
                    <div>
                        <h2 id="caregiver" class="counter">{{careGiverCounts}}</h2>
                        <p>Total Number Of Registered Caregivers / Companions</p>
                    </div>
                </div>



                <div class="total-count total-gs  col-lg-5 mt-3 card-border" style="cursor: unset;">
                    <!-- <div class="total-count total-gs  col-lg-5 mt-3 card-border" routerLink="/analytics-dashboard/age-group"> -->
                    <img src="../../../assets/little/SVG_LOGO/SVG_LOGO/Active age group.svg" alt="" height="60" width="60">
                    <div>
                        <h2 id="booking">{{bookingCount}}</h2>
                        <p>Total Number of Bookings Per Caregiver / Companion</p>
                    </div>
                </div>

                <div class="total-count total-resources  col-lg-5 mt-3 card-border"  style="cursor: unset;">
                    <!-- <div class="total-count total-resources  col-lg-5 mt-3 card-border" routerLink="/analytics-dashboard/total-earning"> -->
                    <img src="../../../assets/little/SVG_LOGO/SVG_LOGO/View Feedback.svg" alt="" height="60" width="60">
                    <div>
                        <h2 id="income">{{incomeCount}}</h2>
                        <p>Total Earnings Per Week Of a Caregiver / Companion</p>
                    </div>
                </div>

            </div>
        </div>


</div>